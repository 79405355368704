import React from "react"
// import { Link } from "gatsby";

import Layout from "../layouts/layout"
// import Image from "../components/image";
import SEO from "../components/seo"
import { UiText, Heading } from "../components"
import { css } from "styled-components"
const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <main
      css={css`
        position: relative;
        max-width: 960px;
        margin: auto;
        padding: 40px 0px;
      `}
    >
      <Heading level={1} as="h1" variant="h1">
        Contact
      </Heading>
      <UiText variant="content">
        Do you have any question or are you interested in working with me?
        <br />
        Contact me at {/* <a href="mailto:souleymane11dembele@gmail.com"> */}
        <a href="https://www.linkedin.com/in/souleymane-dembele-124761147/">
          {/* souleymane11dembele@gmail.com */}
          in/souleymane-dembele-124761147
        </a>
      </UiText>
    </main>
  </Layout>
)

export default ContactPage
